import { reactive, readonly } from 'vue'

const state = reactive({
  roofType: null,
  degree: null,
  tileShape: null,
  generonOption: null,
  gutterOption: null,
  tile: null,
  results: null
})

export default function useFormState() {
  const clearByDependencies = data => {
    if ('roofType' in data && data.roofType !== state.roofType) {
      Object.assign(data, {
        tileShape: null
      })
    }

    if ('tileShape' in data && data.tileShape !== state.tileShape) {
      Object.assign(data, {
        tile: null,
        generonOption: null
      })
    }

    return data
  }

  const updateFormState = data => {
    if (!data) return

    Object.assign(state, clearByDependencies(data))
  }

  const getFieldValue = field => {
    if (!(field in state)) {
      return false
    }

    return JSON.parse(JSON.stringify(state[field]))
  }

  return {
    formState: readonly(state),
    updateFormState,
    getFieldValue
  }
}
