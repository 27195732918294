import _ from 'lodash'

const LANGUAGE_CONFIG = {
  hu: {
    steps: [
      'RoofType',
      'Measurements',
      'TileShape',
      'Generon',
      'Tiles',
      'Gutter',
      'Accessory',
      'Questions',
      'SummaryStep'
    ],
    minDegree: 15,
    showBanners: true,
    hasEnvirosense: true,
    hasTetotepitek: true
  },
  sk: {
    steps: ['RoofType', 'Measurements', 'TileShape', 'Tiles', 'Questions', 'SummaryStep'],
    minDegree: 10,
    showBanners: false,
    hasEnvirosense: false,
    hasTetotepitek: false
  },
  sr: {
    steps: ['RoofType', 'Measurements', 'Package', 'TileShape', 'Tiles', 'Questions', 'SummaryStep'],
    minDegree: 15,
    showBanners: false,
    hasEnvirosense: false,
    hasTetotepitek: false
  },
  hr: {
    steps: ['RoofType', 'Measurements', 'Package', 'TileShape', 'Tiles', 'Questions', 'SummaryStep'],
    minDegree: 15,
    showBanners: false,
    hasEnvirosense: false,
    hasTetotepitek: false
  },
  ro: {
    steps: ['RoofType', 'Measurements', 'TileShape', 'Tiles', 'Questions', 'SummaryStep'],
    minDegree: 15,
    showBanners: false,
    hasEnvirosense: false,
    hasTetotepitek: false
  }
}
const LANGUAGE_KEY = 'language'

function getCurrentLanguageFromDomain() {
  const FALLBACK_LANGUAGE = process.env.VUE_APP_DEFAULT_LOCALE
  const URL_MAP = [
    {
      language: 'hu',
      staging: 'tetokalkulator.develop.y-collective.hu',
      production: 'kalkulator.terranteto.hu'
    },
    {
      language: 'sk',
      staging: 'kalkulator.develop.y-collective.hu',
      production: 'kalkulator.terran.sk'
    },
    {
      language: 'sr',
      staging: 'kalkulator-rs.develop.y-collective.hu',
      production: 'kalkulator.terran.rs'
    },
    {
      language: 'hr',
      staging: 'kalkulator-hr.develop.y-collective.hu',
      production: 'kalkulator.terran.hr'
    },
    {
      language: 'ro',
      staging: 'calculatoracoperis.develop.y-collective.hu',
      production: 'calculatoracoperis.tigleterran.ro'
    }
  ]

  const currentLanguage = _.result(
    _.find(URL_MAP, url => url[process.env.NODE_ENV] === window.location.host),
    LANGUAGE_KEY
  )

  return currentLanguage || FALLBACK_LANGUAGE
}

function setLanguageFromDomain() {
  localStorage.setItem(LANGUAGE_KEY, getCurrentLanguageFromDomain())
}

export { setLanguageFromDomain, getCurrentLanguageFromDomain, LANGUAGE_CONFIG }
